body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #745258;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.is-bg-dark {
    background-color: #533B41;
}

.is-bg-light {
    background-color: #A58E91 !important;
}

.is-text-light {
    color: #A58E91 !important;
}

.is-no-top-shadow {
    /*overflow-y: hidden;*/
    margin: 0 0 min(20vw, 100px) 0;
}

.is-inline-flex {
    display: inline-flex;
}

.is-circle {
    border-radius: 50%;
    max-width: 70vw;
}

.is-text-white {
    color: #fff;
}

.is-pfp {
    position: absolute;
    bottom: 0;
    left: calc(24px + 0.5rem);
    max-width: 70vw;
    transform: translate(-50%, 0);
    left: 50%;
}

.centerUntilThing {
    justify-content: center;
}

.hideForNow {
    display: none !important;
}

.appearOnSmallScreens {
    display: flex !important;
}

@media only screen and (min-width: 900px) {
    .is-pfp {
        left: calc(24px + 0.5rem);
        transform: none;
    }
    .centerUntilThing {
        justify-content: initial;
    }
    .hideForNow {
        display: flex !important;
    }
    .appearOnSmallScreens {
        display: none !important;
    }
    .cardMarginFix {
        margin-left: -0.5rem;
    }
}

.smallMarginRight {
    margin-right: .5rem !important;
}

.MuiCardHeader-subheader {
    color: rgba(255, 255, 255, 0.7) !important;
}

.expandButtonTransition {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
}

.MuiTabScrollButton-horizontal {
    background-color: #A58E91 !important;
    opacity: 1 !important;
    border-radius: 4px !important;
}

.sectionHeadingText {
    padding-left: 0.5rem;
}

.webp .neobg {
    background: url('./Images/neobg.webp') no-repeat;
}

.no-webp .neobg {
    background: url('./Images/neobg.png') no-repeat;
}

.webp .topbg {
    background-image: url('./Images/codebg.webp');
}

.no-webp .topbg {
    background-image: url('./Images/codebg.png');
}

.topbg {
    background-repeat: no-repeat;
    background-size: cover;
}

.frost {
    background-color: rgba(83, 59, 65, .70);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.transparent {
    background-color: transparent !important;
}


/* navbar max-width: 1536px */